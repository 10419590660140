import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Image = ({ item }) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "estampas" }
        name: { eq: "camiseta-manga-longa-preta-estampa-branca" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.file.childImageSharp.fluid}
      alt="Camiseta manga longa preta com estampa branca"
    />
  )
}

const StyledImage = styled(Img)`
  border-radius: 10px;
  overflow: hidden;
`

export default Image
