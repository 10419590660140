import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

import CamisetaImage from "../images/camiseta"
import MedidasCamisetaImage from "../images/medidas_camiseta"
import BabyVImage from "../images/baby_gola_v"
import BabyImage from "../images/baby_look"
import MedidasBabyImage from "../images/medidas_baby"
import CamisetaVImage from "../images/camiseta_gola_v"
import InfantilImage from "../images/camiseta_infantil"
import MedidasInfantilImage from "../images/medidas_infantil"
import EcobagImage from "../images/ecobag"
import MangaLongaImage from "../images/manga_longa"
import LongaInfantilImage from "../images/manga_longa_infantil"
import PoloImage from "../images/polo"
import RaglanImage from "../images/raglan"
import RegataImage from "../images/regata"
import RegataMachaImage from "../images/regata_machao"

import Vermont1 from "../images/vermont1"
import Vermont2 from "../images/vermont2"
import Vermont3 from "../images/vermont3"
import Vermont4 from "../images/vermont4"
import Vermont5 from "../images/vermont5"
import Vermont6 from "../images/vermont6"
import Vermont7 from "../images/vermont7"
import Vermont8 from "../images/vermont8"
import Vermont9 from "../images/vermont9"
import Lajinha from "../images/lajinha"
import Musyoga from "../images/musyoga"
import Sewald from "../images/sewald"

const Options = () => (
  <Section>
    <Subtitle>Opções</Subtitle>
    <StyledContainer>

      <OptionsTextGroup>
        <h2>Modelos</h2>
        <ul>
          <li>Cortes masculinos, femininos e infatil.</li>
          <li>Regatas, manga curta e manga longa.</li>
          <li>Gola C e gola V.</li>
        </ul>
      </OptionsTextGroup>

      <ModelsGroup>
        <ModelItem>
          <CamisetaImage></CamisetaImage>
          <span>Camiseta</span>
        </ModelItem>
        <ModelItem>
          <BabyImage></BabyImage>
          <span>Baby Look</span>
        </ModelItem>
        <ModelItem>
          <CamisetaVImage></CamisetaVImage>
          <span>Camiseta Gola V</span>
        </ModelItem>
        <ModelItem>
          <BabyVImage></BabyVImage>
          <span>Baby Look Gola V</span>
        </ModelItem>
        <ModelItem>
          <InfantilImage></InfantilImage>
          <span>Camiseta Infantil</span>
        </ModelItem>
        <ModelItem>
          <MangaLongaImage></MangaLongaImage>
          <span>Manga Longa</span>
        </ModelItem>
        <ModelItem>
          <LongaInfantilImage></LongaInfantilImage>
          <span>Manga Longa Infantil</span>
        </ModelItem>
        <ModelItem>
          <RegataImage></RegataImage>
          <span>Regata</span>
        </ModelItem>
        <ModelItem>
          <RegataMachaImage></RegataMachaImage>
          <span>Regata Machão</span>
        </ModelItem>
        <ModelItem>
          <RaglanImage></RaglanImage>
          <span>Camiseta Raglan</span>
        </ModelItem>
        <ModelItem>
          <PoloImage></PoloImage>
          <span>Polo</span>
        </ModelItem>
        <ModelItem>
          <EcobagImage></EcobagImage>
          <span>Ecobag</span>
        </ModelItem>
      </ModelsGroup>

      <OptionsTextGroup>
        <h2>Cores</h2>
        <ul>
          <li>Cores lisas: branco, preto e coloridas</li>
          <li>Cores mescla: cinza-mescla, preto-mescla, etc.</li>
          <li>Raglan: preto/cinza, preto/branco, etc.</li>
        </ul>
      </OptionsTextGroup>

      <ColorsGroup>
        <ColorItem>
          <Bra></Bra>
          <span>Branco</span>
        </ColorItem>
        <ColorItem>
          <AmaCan />
          <span>Amarelo Canário</span>
        </ColorItem>
        <ColorItem>
          <AmaOuro></AmaOuro>
          <span>Amarelo Ouro</span>
        </ColorItem>
        <ColorItem>
          <Mostarda></Mostarda>
          <span>Mostarda</span>
        </ColorItem>
        <ColorItem>
          <Laranja></Laranja>
          <span>Laranja</span>
        </ColorItem>
        <ColorItem>
          <Bordo></Bordo>
          <span>Bordô</span>
        </ColorItem>
        <ColorItem>
          <Vermelho></Vermelho>
          <span>Vermelho</span>
        </ColorItem>
        <ColorItem>
          <Lilas></Lilas>
          <span>Lilás</span>
        </ColorItem>
        <ColorItem>
          <Roxo></Roxo>
          <span>Roxo</span>
        </ColorItem>
        <ColorItem>
          <RosaBebe></RosaBebe>
          <span>Rosa Bebê</span>
        </ColorItem>
        <ColorItem>
          <RosaGoi></RosaGoi>
          <span>Rosa Goiaba</span>
        </ColorItem>
        <ColorItem>
          <Pink></Pink>
          <span>Rosa Pink</span>
        </ColorItem>
        <ColorItem>
          <Marrom></Marrom>
          <span>Marrom</span>
        </ColorItem>
        <ColorItem>
          <Preto></Preto>
          <span>Preto</span>
        </ColorItem>
        <ColorItem>
          <AzulTur></AzulTur>
          <span>Azul Turquesa</span>
        </ColorItem>
        <ColorItem>
          <Jade></Jade>
          <span>Azul Jade</span>
        </ColorItem>
        <ColorItem>
          <Royal></Royal>
          <span>Azul Royal</span>
        </ColorItem>
        <ColorItem>
          <Marinho></Marinho>
          <span>Azul Marinho</span>
        </ColorItem>
        <ColorItem>
          <VerdeFluor></VerdeFluor>
          <span>Verde Flúor</span>
        </ColorItem>
        <ColorItem>
          <Bandeira></Bandeira>
          <span>Verde Bandeira</span>
        </ColorItem>
        <ColorItem>
          <Folha></Folha>
          <span>Verde Folha</span>
        </ColorItem>
        <ColorItem>
          <Musgo></Musgo>
          <span>Verde Musgo</span>
        </ColorItem>
        <ColorItem>
          <CinzaMedio></CinzaMedio>
          <span>Cinza Médio</span>
        </ColorItem>
        <ColorItem>
          <CinzaMescla></CinzaMescla>
          <span>Cinza Mescla</span>
        </ColorItem>
        <ColorItem>
          <CinzaChumbo></CinzaChumbo>
          <span>Cinza Chumbo</span>
        </ColorItem>
        <ColorItem>
          <PretoMescla></PretoMescla>
          <span>Preto Mescla</span>
        </ColorItem>
        <ColorItem>
          <RagPretoCinza></RagPretoCinza>
          <span>Raglan Pre/Cin</span>
        </ColorItem>
        <ColorItem>
          <RagPretoBranco></RagPretoBranco>
          <span>Raglan Pre/Bra</span>
        </ColorItem>
        <ColorItem>
          <RagMarCinza></RagMarCinza>
          <span>Raglan Mar/Cin</span>
        </ColorItem>
        <ColorItem>
          <RagBordoCinza></RagBordoCinza>
          <span>Raglan Bor/Cin</span>
        </ColorItem>
      </ColorsGroup>

      <OptionsTextGroup>
        <h2>Estampas</h2>
        <ul>
          <li>Formatos de imagem: .png, .jpg</li>
          <li>Formatos vetor: .svg, etc.</li>
          <li>Formatos fotográficos: .psd, etc.</li>
        </ul>
      </OptionsTextGroup>

      <ModelsGroup>
        <EstampaItem>
          <Vermont2></Vermont2>
          <span>"Colorido em malha preta"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont1></Vermont1>
          <span>"Branco em malha bordô"</span>
        </EstampaItem>
        <EstampaItem>
          <Lajinha></Lajinha>
          <span>"Preto em malha branca"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont5></Vermont5>
          <span>"Branco em polo preta"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont7></Vermont7>
          <span>"Colorido em regata cinza mescla"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont9></Vermont9>
          <span>"Branco em manga longa preta"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont4></Vermont4>
          <span>"Colorido em malha marrom"</span>
        </EstampaItem>
        <EstampaItem>
          <Musyoga></Musyoga>
          <span>"Branco em malha preta"</span>
        </EstampaItem>
        <EstampaItem>
          <Sewald></Sewald>
          <span>"Colorido em malha cinza mescla"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont6></Vermont6>
          <span>"Preto em polo branca"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont8></Vermont8>
          <span>"Colorido em regata branca"</span>
        </EstampaItem>
        <EstampaItem>
          <Vermont3></Vermont3>
          <span>"Colorido em malha preta"</span>
        </EstampaItem>
      </ModelsGroup>

      <OptionsTextGroup>
        <h2>Tamanhos</h2>
        <ul>
          <li>Masculino: PP, M, G, GG, XG, XXG e NOBRE</li>
          <li>Feminino: M, G, GG e XG</li>
          <li>Infantil: PP, P, M, G e GG</li>
        </ul>
      </OptionsTextGroup>

      <SizesGroup>
        <SizeItem>
          <MedidasCamisetaImage></MedidasCamisetaImage>
        </SizeItem>

        <GradeSizesGroup>
           <MascSizesItem>
             <span>-</span>
             <span>PP</span>
             <span>P</span>
             <span>M</span>
             <span>G</span>
             <span>GG</span>
             <span>XG</span>
             <span>XXG</span>
             <span>NOBRE</span>
           </MascSizesItem>
           <MascSizesItem>
             <span>C</span>
             <span>68cm</span>
             <span>70cm</span>
             <span>72cm</span>
             <span>74cm</span>
             <span>76cm</span>
             <span>79cm</span>
             <span>81cm</span>
             <span>93cm</span>
           </MascSizesItem>
           <MascSizesItem>
             <span>L</span>
             <span>48cm</span>
             <span>52cm</span>
             <span>54cm</span>
             <span>56cm</span>
             <span>58cm</span>
             <span>61cm</span>
             <span>71cm</span>
             <span>83cm</span>             
           </MascSizesItem>
           <SizesObs>*Pode haver variação de até 2cm</SizesObs>
        </GradeSizesGroup>

        <SizeItem>
          <MedidasBabyImage></MedidasBabyImage>
        </SizeItem> 
        <GradeSizesGroup>
             <FemSizesItem>
               <span>-</span>
               <span>P</span>
               <span>M</span>
               <span>G</span>
               <span>GG</span>
             </FemSizesItem>
             <FemSizesItem>
               <span>C</span>
               <span>53cm</span>
               <span>56cm</span>
               <span>60cm</span>
               <span>64cm</span>
             </FemSizesItem>
             <FemSizesItem>
               <span>L</span>
               <span>34cm</span>
               <span>37cm</span>
               <span>40cm</span>
               <span>43cm</span>
             </FemSizesItem>
             <SizesObs>*Pode haver variação de até 2cm</SizesObs>
        </GradeSizesGroup>
        <SizeItem>
          <MedidasInfantilImage></MedidasInfantilImage>
        </SizeItem> 
        <GradeSizesGroup>
             <InfSizesItem>
               <span>-</span>
               <span>PP</span>
               <span>P</span>
               <span>M</span>
               <span>G</span>
               <span>GG</span>
             </InfSizesItem>
             <InfSizesItem>
               <span>C</span>
               <span>40cm</span>
               <span>46cm</span>
               <span>51cm</span>
               <span>57cm</span>
               <span>62cm</span>
             </InfSizesItem>
             <InfSizesItem>
               <span>L</span>
               <span>30cm</span>
               <span>33cm</span>
               <span>38cm</span>
               <span>42cm</span>
               <span>46cm</span>
             </InfSizesItem>
             <SizesObs>*Pode haver variação de até 2cm</SizesObs>
        </GradeSizesGroup>        
      </SizesGroup>

    </StyledContainer>
  </Section>
)

export default Options

const StyledContainer = styled(Container)`
  width: 75%;

  display: grid;
  grid-template-rows: 0.25fr 3fr 0.25fr 2.5fr 0.25fr 3fr;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    grid-template-rows: 0.1fr 1.5fr 0.1fr 1.25fr 0.1fr 1.5fr;
  }

  max-width: 670px;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const OptionsTextGroup = styled.div`
  margin: 0;
  padding-top: 16px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    ul {
      display: none;
    }
  }

  ul {
    padding: 0;
  }

  li {
    margin-bottom: 16px;
    ${props => props.theme.font_size.small}
  }
`

const ModelsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 12px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
`

const SizesGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 24px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, max-content);
  }
`

const ColorsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 0.5fr);
  grid-template-rows: repeat(5, 0.5);
  grid-row-gap: 12px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: repeat(3, 0.5fr);
    grid-template-rows: repeat(10, 0.125fr);
  }
`

const GradeSizesGroup = styled.div`
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 12px;

  justify-content: center;
  align-content: center;

  div:first-child {
    span {
      ${props => props.theme.font_size.small}
      font-family: ${props => props.theme.font.bold}
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: max-content max-content;
    grid-column-gap: 24px;
  }
`

const SizesObs = styled.span`
  font-family: ${props => props.theme.font.bold};
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-column: 1 / 4;
  }
`

const MascSizesItem = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;

  justify-content: center;
  align-content: center;

  span {
    ${props => props.theme.font_size.xsmall}
    text-align: center;
  }

  span:first-child {
    ${props => props.theme.font_size.small}
    font-family: ${props => props.theme.font.bold}
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 1fr);
    grid-row-gap: 12px;
  }
`

const FemSizesItem = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;

  justify-content: center;
  align-content: center;

  span {
    ${props => props.theme.font_size.xsmall}
    text-align: center;
  }

  span:first-child {
    ${props => props.theme.font_size.small}
    font-family: ${props => props.theme.font.bold}
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 12px;
  }
`

const InfSizesItem = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;

  justify-content: center;
  align-content: center;

  span {
    ${props => props.theme.font_size.xsmall}
    text-align: center;
  }

  span:first-child {
    ${props => props.theme.font_size.small}
    font-family: ${props => props.theme.font.bold}
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 12px;
  }
`

const ModelItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 0.75fr;

  span {
    text-align: center;
    align-self: center;
  }

  div {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-rows: auto;
    div {
      width: 80%;
      margin: 0 auto;
    }
  }
`

const SizeItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;


  span {
    text-align: center;
    align-self: center;
  }

  div {
    width: 60%;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-rows: auto;
    div {
      width: 80%;
      margin: 0 auto;
    }
  }
`


const EstampaItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 0.5fr;

  span {
    text-align: center;
    align-self: center;
    ${props => props.theme.font_size.xxxsmall};
  }

  div {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-rows: auto;
    div {
      width: 80%;
      margin: 0 auto;
    }
  }
`

const ColorItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr 1fr;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-rows: 4fr 18px;
  }

  span {
    text-align: center;
    align-self: center;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  width: 80%;
  margin: 0 auto;
`

const Bra = styled.div`
  background-color: #e7e7e7;
`
const AmaCan = styled.div`
  background-color: #e4ce19;
`
const AmaOuro = styled.div`
  background-color: #ddbd50;
`
const Mostarda = styled.div`
  background-color: #c49a4e;
`
const Laranja = styled.div`
  background-color: #d5732c;
`
const Bordo = styled.div`
  background-color: #682634;
`
const Vermelho = styled.div`
  background-color: #c40d29;
`
const Lilas = styled.div`
  background-color: #8d8ab9;
`
const Roxo = styled.div`
  background-color: #513977;
`
const RosaBebe = styled.div`
  background-color: #fed6d6;
`
const RosaGoi = styled.div`
  background-color: #e19c9f;
`
const Pink = styled.div`
  background-color: #bd3873;
`
const Marrom = styled.div`
  background-color: #6e5447;
`
const Preto = styled.div`
  background-color: #000;
`
const AzulTur = styled.div`
  background-color: #91bed1;
`
const Jade = styled.div`
  background-color: #038086;
`
const Royal = styled.div`
  background-color: #034694;
`
const Marinho = styled.div`
  background-color: #28324d;
`
const VerdeFluor = styled.div`
  background-color: #99bc3e;
`
const Bandeira = styled.div`
  background-color: #0d904e;
`
const Folha = styled.div`
  background-color: #0b4930;
`
const Musgo = styled.div`
  background-color: #253825;
`
const CinzaMedio = styled.div`
  background-color: #d6d6d6;
`
const CinzaMescla = styled.div`
  background-color: #dddddd;
`
const CinzaChumbo = styled.div`
  background-color: #484f59;
`
const PretoMescla = styled.div`
  background-color: #35373b;
`
const RagPretoCinza = styled.div`
  background-image: -webkit-linear-gradient(-45deg, #000 50%, #dddddd 50%);
`
const RagPretoBranco = styled.div`
  background-image: -webkit-linear-gradient(-45deg, #000 50%, #fff 50%);
`
const RagMarCinza = styled.div`
  background-image: -webkit-linear-gradient(-45deg, #28324d 50%, #dddddd 50%);
`
const RagBordoCinza = styled.div`
  background-image: -webkit-linear-gradient(-45deg, #682634 50%, #dddddd 50%);
`
