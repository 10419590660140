import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ item }) => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "mix" }, name: { eq: "ecobag" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // const Image = () => {
  //   const data = useStaticQuery(graphql`
  //     query {
  //       file(sourceInstanceName: { eq: "product" }, name: { eq: "logo_landscape" }) {
  //         childImageSharp {
  //           fixed(width: 255, height: 102) {
  //                     ...GatsbyImageSharpFixed_tracedSVG
  //                   }
  //             }
  //       }
  //     }
  //   `)

  return <Img fluid={data.file.childImageSharp.fluid} alt="Ecobag" />
  // return <Img fixed={data.file.childImageSharp.fixed} />
}

export default Image
