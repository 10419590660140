import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import Header from "../components/sections/camisetasHeader"
import CallToAction from "../components/sections/calltoaction"
import Options from "../components/sections/options"
import Footer from "../components/sections/footer"

const CamisetasPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            models
            colors
          }
        }
      }
    `
  )

  return (
    <Layout>
    <WhatsButton />
      <SEO
        title="Opções de Camisetas [Ouro Estamparia Digital]"
        description="Diversas opções de modelos e cores de camisetas de algodão. Aproveite a flexibilidade do digital."
        pathname="/camisetas"
      />
      <Navigation />
      <Header
        header1={`Camisetas em ${site.siteMetadata.models} modelos e ${site.siteMetadata.colors} cores.`}
        header2="Estampe o que precisar."
        subheader="Nosso processo digital flexibiliza a sua escolha. Estampe no modelo e cor que precisar."
      />
      <Options />
      <CallToAction
        msg1="Entre em contato e faça um orçamento"
        sub="pedido prático e online"
        link="orcamento"
      >
        Encomendar
      </CallToAction>
      <Footer />
    </Layout>
  )
}

export default CamisetasPage
